import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Container, Row, Col, Nav, Accordion } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import "assets/css/faq.css";
import { useGetAllFaqData } from "hooks";
import { SeoHeader } from "common";

const StaticFaqs = {
  category_type: [
    {
      faq_category_id: "1",
      category_name: "Account",
      faq: [
        {
          faq_id: 1,
          question: "How to Change Account?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 2,
          question: "How to change password ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 3,
          question: "How to deactivate account ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
        {
          faq_id: 4,
          question: "How to change mobile number ?",
          answer:
            "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis",
        },
      ],
    },
    {
      faq_category_id: "9",
      category_name: "career",
      faq: [
        {
          faq_id: 8,
          question:
            "This is to check career question. This is for testing Lorem ipsum dolor",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        },
      ],
    },
    {
      faq_category_id: "2",
      category_name: "Job",
      faq: [
        {
          faq_id: 9,
          question: "This is question",
          answer:
            "test answer Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
        },
      ],
    },
  ],
};

function Faq({ getSettingData, t }) {
  const { data: getAllFaqData } = useGetAllFaqData(
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );
  // Extract category_type from the API response
  const faqData = getAllFaqData?.data || StaticFaqs;

  // Set the initial state of activeKey to the first category's faq_category_id
  const initialActiveKey =
    faqData?.category_type?.length > 0
      ? faqData?.category_type[0].faq_category_id
      : "0";
  const [activeKey, setActiveKey] = useState(initialActiveKey);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };
  useEffect(() => {
    if (faqData?.category_type?.length > 0) {
      setActiveKey(faqData?.category_type[0].faq_category_id.toString());
    }
  }, [faqData]);
  return (
    <>
      <SeoHeader title={"Faq"} description={"Faq"} />
      <Container fluid className="mb-5 faq-container">
        <Row className="mt-2 mt-sm-5">
          <Col sm={12} className="title text-center">
            {getSettingData?.data?.faq_setting_faq_title ||
              t("front_page.home_setting_faq_title_static")}
          </Col>
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <p className="title-para">
              {getSettingData?.data?.faq_setting_faq_description}
            </p>
          </Col>
        </Row>
        {faqData.length !== 0 && (
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="1"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Row className="mt-4 px-4">
              <Col sm={12} md={12} xl={2}>
                <Nav className="flex-xl-column flex-lg-row  align-items-start faq-tab justify-content-center justify-content-xl-start">
                  {faqData?.category_type.map((category) => (
                    <Nav.Item key={category.faq_category_id}>
                      <Nav.Link
                        eventKey={category.faq_category_id}
                        className={`faq-link ${
                          activeKey === category.faq_category_id
                            ? "tab-active-class"
                            : ""
                        }`}
                      >
                        {category.category_name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12} md={12} xl={10} className="px-4 mt-5 mt-xl-0">
                <Tab.Content>
                  {faqData?.category_type.map((category) => (
                    <Tab.Pane
                      key={category.faq_category_id}
                      eventKey={category.faq_category_id}
                    >
                      <Accordion defaultActiveKey="0">
                        {category.faq.map((faqItem) => (
                          <Accordion.Item
                            key={faqItem.faq_id}
                            eventKey={faqItem.faq_id.toString()}
                            className="mt-3"
                          >
                            <Accordion.Header className="acc-header">
                              {faqItem.question}
                            </Accordion.Header>
                            <Accordion.Body>{faqItem.answer}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </Container>
    </>
  );
}

Faq.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      faq_setting_faq_title: PropTypes.string,
      faq_setting_faq_description: PropTypes.string,
    }),
  }),
};

export default Faq;
